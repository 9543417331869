<template>
  <div class="resources_view">
    <top-nav active="3" :isRectangle="true" :istop="true"></top-nav>
    <div class="topnav_bg_90"></div>
    <div class="resources">
      <div class="top_article" @click="goRouter('/BlogDetails/'+top_article.webblockid)">
        <img class="top_article_cover_image" :src="top_article.coverimage" alt="">
        <div class="content">
          <p class="top_article_title">{{top_article.title}}</p>
          <p class="top_article_subtitle">{{top_article.subtitle}}</p>
          <p class="top_article_updatetime">{{handleAmPmDate(top_article.updatedtimestamp)}}</p>
        </div>
        <div class="top_article_inside"></div>
      </div>
      
      <div class="recent_article">
        <div class="recent_article_title">Recent Blogs</div>
        <div class="recent_article_list">
          <div :class="{'no_margin_right':(i+1)%4!=0}" class="recent_article_list_item" v-for="(d,i) in blockList" :key="i">
            <div class="recent_article_list_item_img">
              <img :src="d.coverimage+'?x-oss-process=image/resize,m_fill,h_147,w_283'" alt="">
            </div>
            <p @click="goRouter('/BlogDetails/'+d.webblockid)" class="recent_article_list_item_title">
              {{d.title}}
            </p>
            <p class="recent_article_list_item_subtitle">
              {{d.subtitle}}
            </p>
            <p class="recent_article_list_item_updatetime">
              {{handleAmPmDate(d.updatedtimestamp)}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <foot-nav></foot-nav>
  </div>
</template>
<script>
import { getWebBlock } from '@/api/userApi.js'
import { AmPmDateNoTime } from '@/assets/js/time.js'
export default {
  data() {
    return {
      active: '1',
      top_article: {},
      blockList: [],
    }
  },
  created() {
    getWebBlock().then(res => {
      if (res.data.success) {
        this.top_article = res.data.data.shift();
        this.blockList = res.data.data;
      }else{
        
      }
    })
  },

  methods: {
    goRouter(path) {
      this.$router.push(path)
    },
    handleAmPmDate(times) {
      return AmPmDateNoTime(times)
    }
  },
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "foot-nav": resolve => require(["./FooterNav.vue"], resolve),
  },
}
</script>
<style lang="scss" scoped>
.resources_view {
  background: #fff;
}
.resources {
  width: 1210px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  // border: 1px solid red;
  .top_article {
    margin-top: 60px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 420px;
    &:hover {
      cursor: pointer;
    }
    .top_article_inside {
      position: absolute;
      height: 420px;
      width: 100%;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.4);
    }
    .top_article_cover_image {
      width: 100%;
      vertical-align: middle;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    .content{
      position: absolute;
      bottom: 30px;
      left: 30px;
      z-index: 9;
    }
    .top_article_title {
      // position: absolute;
      // bottom: 94px;
      // left: 30px;
      font-family: Poppins-Bold;
      font-size: 20px;
      // font-weight: bold;
      line-height: 1.3;
      text-align: left;
      color: #fff;
      z-index: 2;
    }
    .top_article_subtitle {
      // position: absolute;
      // bottom: 60px;
      // left: 30px;
      z-index: 2;
      font-family: Poppins;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      height: 34px;

    }
    .top_article_updatetime {
      padding-top: 10px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      // line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      // bottom: 30px;
    }
  }
  .recent_article {
    margin: 0 24px 0 24px;
    .recent_article_title {
      margin-top: 40px;      
      font-family: Poppins-Bold;
      font-size: 36px;
      // font-weight: bold;
      line-height: 1.17;
      text-align: left;
      color: #062440;
    }
    .recent_article_list {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      .recent_article_list_item {
        width: 275px;
        height: 305px;
        margin-bottom: 40px;
        box-sizing: border-box;
        position: relative;
        .recent_article_list_item_img {
          width: 283px;
          height: 147px;
          border-radius: 5px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .recent_article_list_item_title {
          margin-top: 10px;
          font-family: Poppins-Bold;
          transition: all 0.3s ease;
            align-self: stretch;
          flex-grow: 0;
          font-size: 20px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: normal;
          text-align: left;
          color: #062440;
          // height: 52px;
          overflow: hidden;
              -webkit-line-clamp:2;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;

          &:hover {
            cursor: pointer;
            color: #0a7aff;
          }
        }
        .recent_article_list_item_subtitle{
          margin-top: 10px;
          font-family: Poppins;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
          line-height: 1.3;
          // height: 78px;
          overflow: hidden;
              -webkit-line-clamp:2;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
        }
        .recent_article_list_item_updatetime {
          margin-top: 28px;
          position: absolute;
          bottom: 0;
          align-self: stretch;
          flex-grow: 0;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #67788c;
        }
      }
      .no_margin_right {
        margin-right: 20px;
      }
    }
  }
}
</style>